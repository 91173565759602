// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Footer_Section_Container {
    width: 100%;
    height: 15vh;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Footer_Section_Container_Box1 {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 2%;
    color: white;
}


@media screen and (max-width: 540px) {
    .Footer_Section_Container_Box1 {
        width: 85%;
        margin: 1%;
    }    
    .Footer_Section_Container_Box1>img{
        width: 30%
    }
    .Footer_Section_Container_Box1>p{
        font-size: 0.6rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/FooterSection.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,UAAU;IACV,UAAU;IACV,YAAY;AAChB;;;AAGA;IACI;QACI,UAAU;QACV,UAAU;IACd;IACA;QACI;IACJ;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["\n.Footer_Section_Container {\n    width: 100%;\n    height: 15vh;\n    background: black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.Footer_Section_Container_Box1 {\n    display: flex;\n    justify-content: space-between;\n    width: 80%;\n    margin: 2%;\n    color: white;\n}\n\n\n@media screen and (max-width: 540px) {\n    .Footer_Section_Container_Box1 {\n        width: 85%;\n        margin: 1%;\n    }    \n    .Footer_Section_Container_Box1>img{\n        width: 30%\n    }\n    .Footer_Section_Container_Box1>p{\n        font-size: 0.6rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
